export default [
  {
    prop: 'id',
    label: 'Mã',
    minWidth: 80,
    type: 'id'
  },
  {
    prop: 'title',
    label: 'Tiêu đề',
    minWidth: 120,
    type: 'text'
  },
  {
    prop: 'type_id',
    label: 'Nhóm',
    minWidth: 100,
    type: 'text'
  },
  {
    prop: 'description',
    label: 'Nội dung',
    minWidth: 300,
    type: 'text'
  },
  {
    prop: 'created_at',
    label: 'Ngày gửi',
    minWidth: 120,
    type: 'text'
  },

]
